import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import countReducer from './countReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  count: countReducer
});

export default rootReducer;

const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    dark: '#C4AF95',
    main: '#C4AF95',
    light: '#C4AF95'
  },
  secondary: {
    contrastText: white,
    dark: '#444444',
    main: '#777777',
    light: '#999999'
  },
  error: {
    contrastText: white,
    dark: '#cd0e0e',
    main: '#ff0000',
    light: '#fb4b4b'
  },
  text: {
    primary: '#444444',
    secondary: '#888888',
    link: '#C4AF95'
  },
  link: '#888888',
  icon: '#444444',
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: '#eeeeee',
  white: white,
};

/* eslint-disable no-undef */
import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_IGUATEMI_DN_API_BASEURL,
});

const handlerRequest = config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const handlerError = error => {
  if (error.response.status === 401) {
    window.location = '/errors/error-401';
  } else if (error.response.status === 500) {
    //window.location = '/errors/error-500';
  } else {
    //window.location = '/errors/error-500';
  }

  return Promise.reject(error);
};

api.interceptors.request.use(
  async config => handlerRequest(config),
  async error => handlerError(error)
);

api.interceptors.response.use(
  async response => response,
  async error => handlerError(error)
);

export default api;

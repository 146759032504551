/* eslint-disable no-undef */
import axios from 'axios';
import Cookies from 'js-cookie';

// eslint-disable-next-line no-unused-vars
const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTHENTICATION_BASEURL
});

export const TOKEN_KEY = 'idn_tkn';
export const isAuthenticated = () => Cookies.get(TOKEN_KEY) !== null;
export const getToken = () => Cookies.get(TOKEN_KEY);

export const authLogin = async (username, password) => {
  const data = new FormData();
  data.append('grant_type', 'password');
  data.append('client_id', process.env.REACT_APP_AUTHENTICATION_CLIENT_ID);
  data.append(
    'client_secret',
    process.env.REACT_APP_AUTHENTICATION_CLIENT_SECRET
  );
  data.append('username', username);
  data.append('password', password);

  try {
    const response = await apiAuth.post('/connect/token', data);
    if (response.data === null) {
      throw new Error(response);
    }

    Cookies.set(TOKEN_KEY, response.data.access_token, {
      expires: response.data.expires_in / 86400
    });

    return true;
  } catch (ex) {
    return false;
  }
};

export const authLoginToken = async token => {
  try {
    const data = new FormData();
    data.append('grant_type', 'delegation');
    data.append('client_id', process.env.REACT_APP_AUTHENTICATION_CLIENT_ID);
    data.append(
      'client_secret',
      process.env.REACT_APP_AUTHENTICATION_CLIENT_SECRET
    );
    data.append('token', token);

    const response = await apiAuth.post('/connect/token', data);
    if (response.data === null) {
      throw new Error(response);
    }

      Cookies.set(TOKEN_KEY, response.data.access_token, {
        expires: response.data.expires_in / 86400
      });

      return true;
  } catch (ex) {
    return false;
  }
};


export const authLogout = () => {
  Cookies.remove(TOKEN_KEY);
};

import React from 'react';
import MailIcon from '@material-ui/icons/MailOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import LabelAuthorizedCount from 'components/LabelAuthorizedCount';

export default [
  {
    items: [
      {
        title: 'Campanha',
        href: '/campaign',
        icon: MailIcon,
        items: [
          {
            title: 'Lista de campanhas',
            href: '/campaigns'
          },
          {
            title: 'Criar campanha',
            href: '/campaign/create',
            roles: ['Administrator', 'Editor', 'Contributor']
          }
        ]
      },
      {
        title: 'Usuários',
        href: '/user',
        icon: PeopleIcon,
        roles: ['Administrator'],
        items: [
          {
            title: 'Lista de usuários',
            href: '/users'
          },
          {
            title: 'Usuários pendentes',
            href: '/users/authorized',
            label: () => (
              <LabelAuthorizedCount color="#ff2d64" shape="rounded" />
            )
          },
          {
            title: 'Meu perfil',
            href: '/user/profile'
          }
        ]
      },
      {
        title: 'Configurações',
        href: '/setting',
        icon: SettingsIcon,
        roles: ['Administrator'],
        items: [
          {
            title: 'Shoppings',
            href: '/setting/shoppings'
          },
          {
            title: 'Seed list',
            href: '/setting/seed-list'
          }
          // {
          //   title: 'Campanha',
          //   href: '/setting/newsletter/spam'
          // }
        ]
      }
    ]
  }
];

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { getToken } from 'services/auth';
import { UserService } from 'services/user';
import { login, logout } from 'actions';

function AuthGuard(roles) {
  const session = useSelector(state => state.session);
  const dispatch = useDispatch();
  const token = getToken();
  const history = useHistory();

  useEffect(() => {
    const handlerAuth = async () => {
      if (token !== undefined && (!session.loggedIn || session.user.id <= 0)) {
        const user = await UserService.getUserDetail();
        dispatch(login(user));
        return;
      }

      if (!session.loggedIn || session.user.id <= 0) {
        dispatch(logout());
        history.push('/auth/signin-oidc');
        return;
      }

      if (!roles.includes(session.user.userType.code)) {
        history.push('/errors/error-401');
      }
    };

    handlerAuth();
  }, [
    dispatch,
    history,
    roles,
    session.loggedIn,
    session.user.userType,
    session.user.id,
    token
  ]);
}

AuthGuard.propTypes = {
  roles: PropTypes.array.isRequired
};

export default AuthGuard;

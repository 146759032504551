/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import MasterUnAuthLayout from 'layouts/MasterUnAuth';
import MasterLayout from 'layouts/Master';
import ErrorLayout from 'layouts/Error';
import PageCleanLayout from 'layouts/PageClean';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/campaigns" />
  },
  {
    path: '/redirect',
    component: PageCleanLayout,
    routes:[
      {
        path: '',
        exact: true,
        component: lazy(() => import('views/Helper/Redirect'))
      },
    ]
  },
  {
    path: '/auth',
    component: MasterUnAuthLayout,
    routes: [
      // {
      //   path: '/auth/login',
      //   exact: true,
      //   component: lazy(() => import('views/Auth/Login'))
      // },
      {
        path: '/auth/logout',
        exact: true,
        component: lazy(() => import('views/Auth/Logout'))
      },
      {
        path: '/auth/confirmation-register',
        exact: true,
        component: lazy(() => import('views/Auth/ConfirmationRegister'))
      },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(() => import('views/Auth/ResetPassword'))
      },
      {
        path: '/auth/signin-oidc',
        exact: true,
        component: lazy(() => import('views/Auth/Login/SigninOidc'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/preview',
    component: PageCleanLayout,
    routes: [
      {
        path: '/preview/template/:companyCode/:templateId',
        exact: true,
        component: lazy(() => import('views/Campaign/Preview'))
      },
      {
        path: '/preview/campaign/:campaignId',
        exact: true,
        component: lazy(() => import('views/Campaign/Preview'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: MasterLayout,
    routes: [
      {
        path: '/campaigns',
        exact: true,
        component: lazy(() => import('views/Campaign/CampaignList'))
      },
      {
        path: '/campaign/create',
        exact: true,
        component: lazy(() => import('views/Campaign/Template'))
      },
      {
        path: '/campaign/:campaignId/customization/:templateId',
        exact: true,
        component: lazy(() => import('views/Campaign/Customization'))
      },
      {
        path: '/campaign/customization/:companyCode/:templateId',
        exact: true,
        component: lazy(() => import('views/Campaign/Customization'))
      },
      {
        path: '/campaign/:campaignId/configuration',
        exact: true,
        component: lazy(() => import('views/Campaign/Configuration'))
      },
      {
        path: '/users',
        exact: true,
        component: lazy(() => import('views/User/UserList'))
      },
      {
        path: '/users/:authorized',
        exact: true,
        component: lazy(() => import('views/User/UserList'))
      },
      {
        path: '/user/:page',
        exact: true,
        component: lazy(() => import('views/User/Management'))
      },
      {
        path: '/user/configuration/:userId',
        exact: true,
        component: lazy(() => import('views/User/Management'))
      },
      {
        path: '/setting/shoppings',
        exact: true,
        component: lazy(() => import('views/Setting/Company/CompanyList'))
      },
      {
        path: '/setting/seed-list',
        exact: true,
        component: lazy(() => import('views/Setting/SeedList'))
      },
      {
        path: '/setting/newsletter/spam',
        exact: true,
        component: lazy(() => import('views/Setting/Newsletter/Spam'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
